<script setup>
const props = defineProps(['name', 'title', 'location', 'locationSlug', 'image', 'link']);
const defaultImage = '/img/dummy/ngp.png';
</script>

<template>
  <div class="person-strip">
    <div class="person-strip__image">
      <!-- eslint-disable-next-line vue/require-component-is -->
      <!-- <component :is="link ? 'nuxt-link' : 'div'" :to="link"> -->
      <img :src="image || defaultImage" :alt="name" class="person-strip__image-element" />
      <!-- </component>-->
    </div>
    <div class="person-strip__main">
      <div class="person-strip__name">
        <nuxt-link v-if="link" :to="link" class="person-strip__name-text">
          {{ name }}
        </nuxt-link>
        <span v-else>{{ name }}</span>
      </div>

      <div v-if="title" class="person-strip__meta">{{ title }}</div>
      <!-- <div v-if="location" class="person-strip__meta">
        <nuxt-link
          v-if="locationSlug"
          :to="
            localePath({
              name: 'about-buildings-slug',
              params: { slug: locationSlug },
            })
          "
          class="link"
        >
          {{ location }}
        </nuxt-link>
        <span v-else>{{ location }}</span>
      </div> -->
    </div>
  </div>
</template>

<style lang="scss">
.person-strip {
  align-items: center;
  display: flex;

  &__image {
    width: 80px;
  }

  &__image-element {
    width: 100%;
    height: 80px;
    object-fit: cover;
  }

  &__main {
    flex: 1;
    padding-left: 32px;
  }

  &__name,
  &__meta {
    font-size: 16px;
    line-height: 1.5;
  }

  &__name {
    font-weight: 500;
    text-transform: uppercase;
  }

  &__name-text {
    @include underline-transition(1px);
  }

  &__meta {
  }
}
</style>
